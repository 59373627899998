import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class FormUtil {
  public static markAsTouched(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.markAsTouched(control);
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((arrayControl: UntypedFormGroup) => {
          this.markAsTouched(arrayControl);
        });
      }
    });
  }

  public static rangeFilter(filters: any, from: any, to: any): void {
    if (from && (to === null || to === undefined || to === '')) {
      filters['max_units'] = { value: from, matchMode: 'greater' };
    } else if (to && (from === null || from === undefined || from === '')) {
      filters['max_units'] = { value: to, matchMode: 'less' };
    } else {
      filters['max_units'] = { value: [from, to], matchMode: 'between' };
    }
  }

  public static isRequired(formControl: UntypedFormControl | AbstractControl): boolean {
    const emptyFormControl = new UntypedFormControl();

    if (formControl && formControl.validator) {
      const validationResult = formControl.validator(emptyFormControl);

      return validationResult !== null && validationResult.required === true;
    }

    return false;
  }

  public static UpdateValueAndValidity(
    group: UntypedFormGroup,
    opts: { onlySelf?: boolean; emitEvent?: boolean } = { onlySelf: true, emitEvent: false },
  ): void {
    if (group) {
      Object.keys(group.controls).forEach((key) => {
        return group.get(key).updateValueAndValidity(opts);
      });
    }
  }
}
